@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-Light-BETA.ttf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-Medium.ttf");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "inter";
  src: url("./assets/fonts/inter/Inter-SemiBold.ttf");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter/Inter-Bold.ttf");
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/gilroy/Gilroy-Black.ttf");
  font-style: normal;
  font-weight: 800;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

